/*!
 * Transfer departure
 */

const wrap = '#transferFormDeparture';

let actionButton = '#submitTransferDeparture';

let callForm = '#formTransferDeparture';

let target;

let json = {};

let blockSuccess = '.success-message-transfer';


/**
 * Remove all error in form
 */
let removeErrors = () => {
    $('span.help-block', callForm).remove('span.help-block');
    $('.has-error', callForm).removeClass('has-error');
};

/**
 * Show send process
 * @private
 */
let showProcess = () => {
    target.addClass('disabled');
    $('i', target).removeClass('fa-angle-right');
    $('i', target).addClass('fa-spinner fa-pulse');
};

// Show send process
let hideProcess = () => {
    target.removeClass('disabled');
    $('i', target).removeClass('fa-spinner fa-pulse');
    $('i', target).addClass('fa-angle-right');
};

/**
 * Clear form
 */
let clearForm = () => {
    $.each(json, (i) => {
        $(`input[name='${i}']`, callForm).val('');
        $(`textarea[name='${i}']`, callForm).val('');

        if (i === 'phone') {
            $(`input[name='${i}']`, callForm).val('7');
        }
    });
};

/**
 * Show success message
 * @param response
 */
let showSuccessMessage = (response) => {
    $(blockSuccess, callForm).removeClass('hide')
        .html(`<span>Спасибо! <br> ${response}</span>`);
    $.scrollTo(callForm);
};

/**
 * Hide success message
 */
let hideSuccessMessage = () => {
    $(blockSuccess, callForm).addClass('hide').html('');
};

/**
 * List errors
 * @param nameError
 * @param label
 * @returns {*}
 */
let getTextError = (nameError, label) => {
    let list = {
        empty: `Поле "${label.slice(0, -1)}" не может быть пустым!`,
        unCorrectPhone: `В поле "${label.slice(0, -1)}" не корректный номер телефона!`,
        notEmail: `В поле "${label.slice(0, -1)}" содержится не корректный email!`,
    };

    return list[nameError];
};

/**
 * Set errors
 * @param inputName
 * @param errorName
 */
let showError = (inputName, errorName) => {
    let name = $(`input[name='${inputName}']`, callForm);

    if (!name.is('input')) {
        name = $(`textarea[name='${inputName}']`, callForm);
    }

    if (inputName === 'place') {
        name = $('#cat_id_dep', callForm);
    }

    let label = $(`label[for='${inputName}']`, callForm).text();

    let textError = getTextError(errorName, label);

    name.closest('.group-form').addClass('has-error');
    name.after(`<span class="help-block text-danger">${textError}</span>`);
};

/**
 * Close ModalJs
 * @param ms
 */
let closeModal = (ms) => {
    setTimeout(() => {
        hideSuccessMessage();
    }, ms);
};

/**
 * Set errors
 * @param inputName
 * @param textError
 */
let showServerError = (inputName, textError) => {
    let name = $(`input[name='${inputName}']`, callForm);

    if (!name.length) {
        name = $(`textarea[name='${inputName}']`, callForm);
    }

    if (name.length) {
        name.closest('.group-form').addClass('has-error');
        name.after(`<span class="help-block text-danger">${textError}</span>`);
    }
};

/**
 * Set data from sent
 * @param data
 * @private
 */
let setData = (data) => {
    json = data;
};

/**
 * Send data
 */
let sentData = () => {
    $.ajax({
        url: '/pages/departure.html',
        dataType: 'json',
        data: json,
        type: 'post',
        async: false,
        beforeSend() {
            showProcess();
        },
        success(data) {
            $.each(data, (i, val) => {
                if (i === 'success') {
                    clearForm();
                    showSuccessMessage(val);
                    closeModal(5000);
                }

                if (i === 'errors') {
                    removeErrors();

                    $.each(val, (name, item) => {
                        showServerError(name, item);
                    });
                }
            });
        },
        complete() {
            hideProcess();
        },
    });
};

/**
 * Get form data
 */
let getFormData = () => {
    let data = {};

    let place = $('#cat_id_dep', callForm).val();

    $.map($(callForm).serializeArray(), (n) => {
        data[n.name] = n.value;
    });

    data.place = place === '-- Выбрать --' ? '-- не указано --' : place;
    data.send = true;
    setData(data);

    return data;
};

/**
 * Check email
 * @param email
 * @param strict
 * @returns {boolean}
 */
let isValidEmail = (email, strict) => {
    if (!strict) {
        email = email.replace(/^\s+|\s+$/g, '');
    }

    return (/^([a-z0-9_-]+\.)*[a-z0-9_-]+@([a-z0-9][a-z0-9-]*[a-z0-9]\.)+[a-z]{2,4}$/i).test(email);
};

/**
 * Check data from form
 * @returns {boolean}
 */
let checkFormData = () => {
    let data = getFormData();

    let result = true;

    $.each(data, (i, val) => {
        if (val.length < 1) {
            if (i !== 'etc') {
                showError(i, 'empty');
                result = false;
            }
        }

        if (i === 'email' && val.length > 0 && !isValidEmail(val)) {
            showError(i, 'notEmail');
            result = false;
        }

        if (i === 'phone' && val.replace(/[+\-()_ ]/g, '').length < 11) {
            showError(i, 'unCorrectPhone');
            result = false;
        }

        if (i === 'place' && val === '-- не указано --') {
            showError(i, 'empty');
            result = false;
        }
    });

    return result;
};

/**
 * Sets listens for events
 */
let setUpListens = () => {
    $(actionButton).on('click', (e) => {
        e.preventDefault();
        target = $(e.target);

        if (target.hasClass('disabled')) {
            return;
        }

        removeErrors();

        let result = checkFormData();

        if (result === true) {
            sentData();
        }
    });
};

/**
 * Init list
 */
let init = () => {
    setUpListens();
};

export {init as initFormDeparture, wrap as idFormDeparture};
